<template>
  <div class="main-wrapper">
    <NavbarDashboard></NavbarDashboard>
    <SidebarDashboard></SidebarDashboard>
    <div class="main-content">
      <form @submit.prevent="onSubmit">
        <section class="section">
          <div class="d-flex justify-content-between align-items-center mb-3">
            <div class="">
              <h4 class="title">{{ labelPage }} Pendidikan</h4>
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <router-link to="/data-karyawan">Karyawan</router-link>
                  </li>
                  <li class="breadcrumb-item">
                    <router-link
                      :to="{
                        name: 'DetailEmployee',
                        params: { id: id_karyawan },
                      }"
                      >Detail Karyawan</router-link
                    >
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    {{ labelPage }} Pendidikan
                  </li>
                </ol>
              </nav>
            </div>

            <div
              class="button-edit"
              v-show="validated == 1 && labelPage == 'Edit'"
            >
              <button class="btn btn-add" type="button" @click="editable">
                <img src="/img/icon-edit1.svg" alt="" style="width: 20px" />
                Edit
              </button>
            </div>
            <div
              v-show="
                (validated != 1 && labelPage == 'Edit') || labelPage == 'Tambah'
              "
            >
              <button class="btn btn-save mr-3" :disabled="isSubmit">
                <span class="fas fa-spinner fa-spin" v-if="isSubmit"></span>
                Simpan
              </button>
              <button
                class="btn btn-blue"
                type="button"
                v-if="enableSync"
                @click="onSync"
                :disabled="isSubmit"
              >
                <span class="fas fa-spinner fa-spin" v-if="isSubmit"></span>
                Simpan & Sync
              </button>
            </div>
          </div>
          <div class="card info-detail">
            <div class="row">
              <div class="col-12">
                <div class="title">{{ labelPage }} Pendidikan</div>
                <div class="sub-title">
                  {{ labelPage }} informasi yang diperlukan
                </div>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-md-6">
                <div class="form-group">
                  <label>Jenjang :</label>
                  <Select2
                    :disabled="validated == 1 && labelPage == 'Edit'"
                    v-model="formData.level"
                    :options="optionTitle"
                    placeholder="Pilih Jenjang"
                    :class="{ 'is-invalid': formError && formError.level }"
                    @change="formChange('level')"
                    @select="formChange('level')"
                  />
                  <div class="form-error" v-if="formError && formError.level">
                    {{ formError.level }}
                  </div>
                </div>

                <div class="form-group">
                  <label>Institusi :</label>
                  <input
                    :disabled="validated == 1 && labelPage == 'Edit'"
                    type="text"
                    class="form-control align-self-center"
                    v-model="formData.institusi"
                    :class="{ 'is-invalid': formError && formError.institusi }"
                    @keyup="formChange('institusi')"
                    placeholder="Masukkan Institusi"
                  />
                  <div
                    class="form-error"
                    v-if="formError && formError.institusi"
                  >
                    {{ formError.institusi }}
                  </div>
                </div>

                <div class="form-group">
                  <label>Jurusan :</label>
                  <input
                    :disabled="validated == 1 && labelPage == 'Edit'"
                    type="text"
                    class="form-control align-self-center"
                    v-model="formData.jurusan"
                    :class="{ 'is-invalid': formError && formError.jurusan }"
                    @keyup="formChange('jurusan')"
                    placeholder="Masukkan Jurusan"
                  />
                  <div class="form-error" v-if="formError && formError.jurusan">
                    {{ formError.jurusan }}
                  </div>
                  <!-- <Select2
                  v-model="major"
                  :options="optionMajor"
                  placeholder="Pilih Jurusan"
                  class="align-self-center"
                  :settings="{ settingOption: value, settingOption: value }"
                  @change="myChangeEvent($event)"
                  @select="mySelectEvent($event)"
                /> -->
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Tahun Mulai :</label>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <div
                        class="input-group-text"
                        :style="[
                          validated == 1 && labelPage == 'Edit'
                            ? { background: '#f3f4f4' }
                            : { background: '#fff' },
                        ]"
                      >
                        <svg
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          width="32"
                          height="32"
                          viewBox="0 0 32 32"
                          style="color: #959595; width: 1rem; height: 1rem"
                        >
                          <path
                            d="M29.333 8c0-2.208-1.792-4-4-4h-18.667c-2.208 0-4 1.792-4 4v18.667c0 2.208 1.792 4 4 4h18.667c2.208 0 4-1.792 4-4v-18.667zM26.667 8v18.667c0 0.736-0.597 1.333-1.333 1.333 0 0-18.667 0-18.667 0-0.736 0-1.333-0.597-1.333-1.333 0 0 0-18.667 0-18.667 0-0.736 0.597-1.333 1.333-1.333 0 0 18.667 0 18.667 0 0.736 0 1.333 0.597 1.333 1.333z"
                            style="fill: #959595"
                          ></path>
                          <path
                            d="M20 2.667v5.333c0 0.736 0.597 1.333 1.333 1.333s1.333-0.597 1.333-1.333v-5.333c0-0.736-0.597-1.333-1.333-1.333s-1.333 0.597-1.333 1.333z"
                            style="fill: #959595"
                          ></path>
                          <path
                            d="M9.333 2.667v5.333c0 0.736 0.597 1.333 1.333 1.333s1.333-0.597 1.333-1.333v-5.333c0-0.736-0.597-1.333-1.333-1.333s-1.333 0.597-1.333 1.333z"
                            style="fill: #959595"
                          ></path>
                          <path
                            d="M4 14.667h24c0.736 0 1.333-0.597 1.333-1.333s-0.597-1.333-1.333-1.333h-24c-0.736 0-1.333 0.597-1.333 1.333s0.597 1.333 1.333 1.333z"
                            style="fill: #959595"
                          ></path>
                        </svg>
                      </div>
                    </div>
                    <input
                      :disabled="validated == 1 && labelPage == 'Edit'"
                      type="text"
                      id="tahun_mulai"
                      v-model="formData.tahun_mulai"
                      :class="{
                        'is-invalid': formError && formError.tahun_mulai,
                      }"
                      @keyup="formChange('tahun_mulai')"
                      v-maska="'####'"
                      placeholder="YYYY"
                      class="form-control"
                    />
                  </div>
                  <div
                    class="form-error"
                    v-if="formError && formError.tahun_mulai"
                  >
                    {{ formError.tahun_mulai }}
                  </div>
                </div>

                <div class="form-group">
                  <label>Tahun Berakhir :</label>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <div
                        class="input-group-text"
                        :style="[
                          validated == 1 && labelPage == 'Edit'
                            ? { background: '#f3f4f4' }
                            : { background: '#fff' },
                        ]"
                      >
                        <svg
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          width="32"
                          height="32"
                          viewBox="0 0 32 32"
                          style="color: #959595; width: 1rem; height: 1rem"
                        >
                          <path
                            d="M29.333 8c0-2.208-1.792-4-4-4h-18.667c-2.208 0-4 1.792-4 4v18.667c0 2.208 1.792 4 4 4h18.667c2.208 0 4-1.792 4-4v-18.667zM26.667 8v18.667c0 0.736-0.597 1.333-1.333 1.333 0 0-18.667 0-18.667 0-0.736 0-1.333-0.597-1.333-1.333 0 0 0-18.667 0-18.667 0-0.736 0.597-1.333 1.333-1.333 0 0 18.667 0 18.667 0 0.736 0 1.333 0.597 1.333 1.333z"
                            style="fill: #959595"
                          ></path>
                          <path
                            d="M20 2.667v5.333c0 0.736 0.597 1.333 1.333 1.333s1.333-0.597 1.333-1.333v-5.333c0-0.736-0.597-1.333-1.333-1.333s-1.333 0.597-1.333 1.333z"
                            style="fill: #959595"
                          ></path>
                          <path
                            d="M9.333 2.667v5.333c0 0.736 0.597 1.333 1.333 1.333s1.333-0.597 1.333-1.333v-5.333c0-0.736-0.597-1.333-1.333-1.333s-1.333 0.597-1.333 1.333z"
                            style="fill: #959595"
                          ></path>
                          <path
                            d="M4 14.667h24c0.736 0 1.333-0.597 1.333-1.333s-0.597-1.333-1.333-1.333h-24c-0.736 0-1.333 0.597-1.333 1.333s0.597 1.333 1.333 1.333z"
                            style="fill: #959595"
                          ></path>
                        </svg>
                      </div>
                    </div>
                    <input
                      :disabled="validated == 1 && labelPage == 'Edit'"
                      type="text"
                      id="tahun_selesai"
                      v-model="formData.tahun_selesai"
                      :class="{
                        'is-invalid': formError && formError.tahun_selesai,
                      }"
                      v-maska="'####'"
                      placeholder="YYYY"
                      @keyup="formChange('tahun_selesai')"
                      class="form-control"
                    />
                  </div>
                  <div
                    class="form-error"
                    v-if="formError && formError.tahun_selesai"
                  >
                    {{ formError.tahun_selesai }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </form>
    </div>
  </div>
</template>

<script>
import SidebarDashboard from "../../../components/Sidebar.vue";
import NavbarDashboard from "../../../components/Navbar.vue";
import Select2 from "vue3-select2-component";
// import Datepicker from "vue3-date-time-picker";
import { ref } from "vue";

import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import { checkRules, showAlert, checkApp } from "../../../helper";
import {
  get_KaryawanPendidikanDetail,
  post_KaryawanPendidikanSave,
} from "../../../actions/karyawan";
// import moment from "moment";
import "moment/locale/id";
import { maska } from "maska";
import { get_ListJenjangPendidikan } from "../../../actions/master";

// import $ from "jquery";

// import TableLite from 'vue3-table-lite'
export default {
  name: "dataDepartement",
  components: {
    // FooterDashboard,
    SidebarDashboard,
    NavbarDashboard,
    Select2,
    // Datepicker,
    // TableLite
  },

  directives: {
    maska,
  },

  data() {
    return {
      validated: 1,
      dateStart: null,
      enableSync: checkApp("rawuh") ? true : false,
      dataEnd: null,
      optionTitle: [],
      optionInstitution: ["Universitas Amikom", "Universitas Gadjah Mada"],
      optionMajos: ["Teknik Informatika", "Teknik Sipil"],
      id_karyawan: this.$route.params.id,
      id_edu: this.$route.params.nomor ? this.$route.params.nomor : "",
      labelPage: this.$route.params.nomor ? "Edit" : "Tambah",
      formData: {
        id: this.$route.params.nomor ? this.$route.params.nomor : "",
        id_karyawan: this.$route.params.id,
        level: "",
        institusi: "",
        jurusan: "",
        tahun_mulai: "",
        tahun_selesai: "",
        isSync: false,
      },
      rules: {
        level: {
          required: true,
        },
        institusi: {
          required: true,
        },
        tahun_mulai: {
          required: true,
        },
        tahun_selesai: {
          required: true,
        },
      },
      formError: [],
      isSubmit: false,
    };
  },

  setup() {
    const date = ref(new Date());

    // In case of a range picker, you'll receive [Date, Date]
    const format = (date) => {
      const month = [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ];
      // const day = date.getDate();
      const monthText = month[date.month];
      const year = date.year;

      return `${monthText} ${year}`;
    };
    return {
      date,
      format,
    };
  },

  created() {
    localStorage.setItem("eduTab", true);
    this.getJenjang();
    if (this.id_edu) {
      this.getData();
    }
  },

  methods: {
    editable() {
      this.validated = 2;
    },
    getData() {
      get_KaryawanPendidikanDetail(this.id_edu, (res) => {
        var { data } = res;
        delete data.created_at;
        delete data.updated_at;
        this.formData = data;
        // var ex = data.tahun_mulai.split("-");
        // this.formData.tahun_mulai = { month: parseInt(ex[1]) - 1, year: ex[0] };
        // var exa = data.tahun_selesai.split("-");
        // this.formData.tahun_selesai = {
        //   month: parseInt(exa[1]) - 1,
        //   year: exa[0],
        // };
      });
    },
    getJenjang() {
      get_ListJenjangPendidikan((res) => {
        this.optionTitle = res.list;
      });
    },
    async formChange(key) {
      if (this.rules[key]) {
        this.rules[key].changed = true;
      }
      var check = await checkRules(this.rules, this.formData);
      this.formError = check.error;
    },
    changeDate(data, key) {
      this.formData[key] = data;
      this.formChange(key);
    },

    postData() {
      this.isSubmit = true;
      var data = this.formData;
      post_KaryawanPendidikanSave(
        data,
        (res) => {
          this.isSubmit = false;
          var msg =
            res.response_code == 201
              ? "Data pendidikan berhasil dicatat"
              : "Data pendidikan berhasil diperbarui";
          showAlert(this.$swal, {
            title: "BERHASIL!",
            msg: msg,
            onSubmit: () => {
              localStorage.setItem("eduTab", true);
              this.$router.push({
                name: "DetailEmployee",
                params: {
                  id: this.id_karyawan,
                },
              });
            },
          });
        },
        () => {
          this.isSubmit = false;
          showAlert(this.$swal, {
            title: "GAGAL!",
            msg: "Terjadi kesalahan, silakan ulangi kembali nanti",
            showCancelButton: true,
            showConfirmButton: false,
          });
        }
      );
    },
    async onSubmit() {
      for (const key in this.rules) {
        if (Object.hasOwnProperty.call(this.rules, key)) {
          this.rules[key].changed = true;
        }
      }
      var data = this.formData;
      var check = await checkRules(this.rules, data);
      this.formError = check.error;
      if (check.success) {
        this.formData.isSync = false;
        showAlert(this.$swal, {
          title: "PERHATIAN!",
          msg: this.formData.id
            ? "Apakah anda yakin, Tindakan ini akan menyimpan semua perubahan yang anda lakukan?"
            : "Apakah anda yakin, semua data yang telah anda isikan akan tersimpan?",
          showCancelButton: true,
          cancelButtonText: "Batal",
          confirmButtonText: "Ya, Lanjutkan",
          onSubmit: () => {
            this.postData();
          },
        });
      }
    },

    async onSync() {
      for (const key in this.rules) {
        if (Object.hasOwnProperty.call(this.rules, key)) {
          this.rules[key].changed = true;
        }
      }
      var data = this.formData;
      var check = await checkRules(this.rules, data);
      this.formError = check.error;
      if (check.success) {
        this.formData.isSync = true;
        showAlert(this.$swal, {
          title: "PERHATIAN!",
          msg: this.formData.id
            ? "Apakah anda yakin, Tindakan ini akan menyimpan semua perubahan yang anda lakukan?"
            : "Apakah anda yakin, semua data yang telah anda isikan akan tersimpan?",
          showCancelButton: true,
          cancelButtonText: "Batal",
          confirmButtonText: "Ya, Lanjutkan",
          onSubmit: () => {
            this.postData();
          },
        });
      }
    },
  },
};
</script>

<style scoped src="../../../assets/css/style.css"></style>

<style scoped src="../../../assets/css/custom.css"></style>

<style scoped>
/* .btn-blue {
  padding: 8px 0px;
  width: 120px;
  font-size: 14px;
} */

.info-detail .title {
  font-size: 18px;
  font-weight: 500;
}

.info-detail .sub-title {
  color: #c6c6c6;
  font-size: 12px;
  font-weight: 400;
  margin-top: 10px;
}

.form-edit {
  width: 100%;
}

.title-detail {
  padding: 30px;
}

.text {
  color: #000;
  font-size: 16px;
  font-weight: 500;
  width: 488px;
  max-width: 236px;
}

.value {
  color: #000;
  font-size: 16px;
  font-weight: 400;
}

.info-detail {
  padding: 30px;
}

hr {
  margin: 0px;
  padding: 0px;
}

.row {
  /* border: 1px solid #ECEFF1; */
  border-radius: 10px;
}

.name-employee {
  font-size: 18px;
  font-weight: 600;
  margin-top: 1rem;
}

.position {
  color: #c6c6c6;
  font-size: 14px;
  margin-top: 0.5rem;
}

.menu .nav-link {
  color: #000;
}

.menu .nav-link.active {
  background: #68caf8;
  color: #fff;
  border-radius: 5px;
  padding: 10px 30px;
}

.menu {
  border: none;
  background: none;
}

.list-menu {
  margin-top: 30px;
}

.btn-save {
  background-color: #40ddd4;
  color: #fff;
  /* width: 90px; */
  font-size: 16px;
  font-weight: 400;
  padding: 10px 0px;
}

.card {
  border: 1px solid #e3e3e3;
  border-radius: 10px;
}
</style>
